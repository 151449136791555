import * as React from 'react';
import Modal from 'react-modal';

import { useIconForSubCategory } from '../DnDTable/hooks';
import {
  Label,
  Data,
  LabelDataWrapper,
  Title,
  SubTitle,
  CloseButton,
  DataWrapper,
} from './styled-components';

const labelAndData = (label, data) => {
  if (!data) return null;
  return (
    <LabelDataWrapper>
      <Label>{label}</Label>
      <Data>{data}</Data>
    </LabelDataWrapper>
  );
};

const CardModal = ({ isOpen, cardData, setOpenCard }) => {
  const icon = useIconForSubCategory(cardData?.type);

  React.useEffect(() => {
    isOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setOpenCard('')}
      contentLabel="Example Modal"
      appElement={document.body}
      style={{
        content: {
          border: `5px solid ${cardData.borderColor}`,
          whiteSpace: 'pre-line',
          width: '75vh',
          height: '50vh',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <CloseButton onClick={() => setOpenCard('')}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.57536 3.69665L6.87862 8.99991L1.57537 14.3032C0.989584 14.8889 0.989584 15.8387 1.57537 16.4245C2.16116 17.0103 3.1109 17.0103 3.69669 16.4245L8.99994 11.1212L14.3033 16.4246C14.8891 17.0104 15.8388 17.0104 16.4246 16.4246C17.0104 15.8388 17.0104 14.889 16.4246 14.3032L11.1213 8.99991L16.4246 3.69655C17.0104 3.11077 17.0104 2.16102 16.4246 1.57523C15.8388 0.989447 14.8891 0.989447 14.3033 1.57523L8.99994 6.87859L3.69668 1.57533C3.11089 0.98954 2.16114 0.98954 1.57536 1.57533C0.989569 2.16111 0.989569 3.11086 1.57536 3.69665Z"
            fill={`${cardData.borderColor}`}
            stroke={`${cardData.borderColor}`}
          />
        </svg>
      </CloseButton>
      {icon && icon}
      <Title color={cardData.titleColor}>{cardData.title}</Title>
      <SubTitle>{cardData.categorization}</SubTitle>
      <DataWrapper>
        {labelAndData(
          'Developmental Outcomes:',
          cardData.developmentalOutcomes
        )}
        {labelAndData('Materials:', cardData.materials || 'None')}
        {labelAndData('Prep:', cardData.prep)}
        {labelAndData('Directions:', cardData.directions)}
      </DataWrapper>
    </Modal>
  );
};

export default CardModal;
