import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import DragCell from './DragCell';
import DropCell from './DropCell';
import { useLocalStorage } from '../../hooks/utils';

import {
  CustomTable,
  TR,
  TH,
  TD,
  TDLabel,
  DragWrapper,
  CellSearchBar,
  SearchWrapper,
} from './styled-components';

import data from '../../data.json';
import { weekdays } from './constants';

import { useActivityLabels, useBuiltLabels } from './hooks';

const Table = memo(({ weekNum: num }) => {
  const weekNum = num - 1;

  const [borderColor, setBorderColor] = useState(null);

  const activityLabels = useActivityLabels(num);

  const builtLables = useBuiltLabels(activityLabels);

  const activityDnd = [[...builtLables], [...builtLables], [...builtLables]];

  const activityCards = useMemo(
    () =>
      data[num].map((card) => ({
        name: card.title,
        type: card.categorization,
        ...card,
      })),
    [num]
  );

  const [cellSearch, setCellSearch] = useState('');
  const [cells, setCells] = useLocalStorage(`cells`, activityDnd);
  const [boxes, setBoxes] = useState(activityCards);
  const [droppedCellNames, setDroppedCellNames] = useLocalStorage(
    `droppedCellNames`,
    [[], [], []]
  );

  useEffect(() => {
    setBoxes(activityCards);
  }, [num, setBoxes, activityCards]);

  const searchResultBoxes = useMemo(() => {
    if (!cellSearch) {
      return boxes;
    }
    return boxes.filter(
      (box) =>
        box.name.toLowerCase().includes(cellSearch.toLowerCase()) ||
        box.categorization.toLowerCase().includes(cellSearch.toLowerCase()) ||
        box?.cardType?.toLowerCase()?.includes(cellSearch.toLowerCase()) ||
        box?.developmentalOutcomes
          ?.toLowerCase()
          ?.includes(cellSearch.toLowerCase()) ||
        box?.materials?.toLowerCase()?.includes(cellSearch.toLowerCase()) ||
        box?.prep?.toLowerCase()?.includes(cellSearch.toLowerCase()) ||
        box?.directions?.toLowerCase()?.includes(cellSearch.toLowerCase())
    );
  }, [cellSearch, boxes]);
  function isDropped(boxName) {
    return droppedCellNames[weekNum].indexOf(boxName) > -1;
  }
  const handleDrop = useCallback(
    (idx, index, item) => {
      const { name } = item;

      cells[weekNum][index] = Object.values({
        ...cells[weekNum][index],
        [idx]: {
          lastDroppedItem: {
            $set: item,
          },
        },
      });

      const copy = [...droppedCellNames];

      copy[weekNum] = [...droppedCellNames[weekNum], name];

      setDroppedCellNames(copy);

      setCells(cells);
    },
    [droppedCellNames, cells, setCells, setDroppedCellNames, weekNum]
  );

  const undoDrop = (name) => {
    let copy = [...droppedCellNames];
    const indexOfItem = copy[weekNum].indexOf(name);

    if (indexOfItem > -1) {
      copy[weekNum].splice(indexOfItem, 1);
    }

    setDroppedCellNames(copy);

    const cellCopy = [...cells];

    cellCopy[weekNum] = cellCopy[weekNum].map((container) =>
      container.map((item) => {
        if (item?.lastDroppedItem?.$set?.name === name) {
          item.lastDroppedItem = null;
        }
        return item;
      })
    );

    setCells(cellCopy);
  };

  return (
    <>
      <CustomTable id={`download${weekNum + 1}`}>
        <thead>
          <TR>
            {weekdays.map((weekday, idx) => (
              <TH hide={idx === 0} key={weekday}>
                {weekday}
              </TH>
            ))}
          </TR>
        </thead>
        <tbody style={{ border: '2px solid black' }}>
          {activityLabels.map((activityLabel, idx) => (
            <TR key={idx}>
              <TD>
                <TDLabel>{activityLabels[idx]}</TDLabel>
              </TD>
              {cells[weekNum].map((dustbin, index) => {
                const isFriday = index === 4;
                return (
                  <DropCell
                    accept={isFriday ? activityLabels : activityLabels[idx]}
                    lastDroppedItem={dustbin[idx]?.lastDroppedItem}
                    onDrop={(item) => handleDrop(idx, index, item)}
                    canDrop={!dustbin[idx]?.lastDroppedItem}
                    activityCards={activityCards}
                    key={index}
                    idx={index}
                    index={idx}
                    isFriday={isFriday}
                    borderColor={borderColor}
                  />
                );
              })}
            </TR>
          ))}
        </tbody>
      </CustomTable>
      <DragWrapper>
        <SearchWrapper>
          <CellSearchBar
            placeholder="Search for an activity..."
            value={cellSearch}
            onChange={(e) => setCellSearch(e.target.value)}
          />
        </SearchWrapper>
        {searchResultBoxes.map(
          ({ name, type, borderColor, categorization }, index) => (
            <DragCell
              name={name}
              type={type}
              isDropped={isDropped(name)}
              key={index}
              undoDrop={undoDrop}
              borderColor={borderColor}
              category={categorization}
              setBorderColor={setBorderColor}
            />
          )
        )}
      </DragWrapper>
    </>
  );
});

export default Table;
