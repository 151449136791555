import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const downloadWeek = (weekNum) => {
  const input = document.getElementById(`download${weekNum}`);

  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    pdf.addImage(imgData, 'JPEG', 0, 0, 210, 300);
    pdf.save('download.pdf');
  });
};
