import * as React from 'react';

import Logo from '../Logo';

import { WebOnlyWrapper, WebOnlyContainer } from './styled-components';

const WebOnlyOverlay = () => {
  return (
    <WebOnlyWrapper className="webOnly">
      <Logo />
      <WebOnlyContainer>
        <h1>
          Sorry, this app is not available on mobile devices. Please visit on a
          desktop device to access.
        </h1>
      </WebOnlyContainer>
    </WebOnlyWrapper>
  );
};

export default WebOnlyOverlay;
