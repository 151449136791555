import styled from 'styled-components';

import chevron from '../../assets/chevron.svg';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  align-items: center;
  jusitfy-content: center;
  width: auto;
  align-self: center;
  margin-top: 25px;
  > h1 {
    color: white;
    margin: 0;
    margin-bottom: 5px;
    font-size: 50px;
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  width: 30vw;
  justify-content: space-between;
  > h2 {
    color: white;
    margin: 0 25px;
    font-size: 30px;
    white-space: nowrap;
  }
`;

export const Chevron = styled.img.attrs({
  src: chevron,
  alt: 'chevron',
})`
  width: 40px;
  height: 40px;
`;

export const ButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  :last-child {
    transform: rotate(180deg);
  }
`;
