import styled from 'styled-components';

export const Image = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 400px;
  height: auto;
  z-index: -1;
`;
