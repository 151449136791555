import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  cursor: move;
  float: left;
  width: 180px;
  height: 90px;
  text-align: center;
  background-color: white;
  display: flex;
  flex-basis: calc(50% - 28px);
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
  ${({ borderColor }) => css`
    border: 4px solid ${borderColor};
  `}
`;

export const NameLabel = styled.span`
  width: 90%;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
`;

export const CategoryLabel = styled(NameLabel)`
  font-weight: medium;
  font-size: 12px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  ${({ isDropped }) => isDropped && 'opacity: 0.5;'}
`;

export const HoverButtonOverlay = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;

  :hover {
    background: white;
    border: none;
    border-radius: 5px;
    opacity: 1;
  }
`;
