import styled from 'styled-components';

export const WebOnlyWrapper = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: auto;
  }
`;

export const WebOnlyContainer = styled.div`
  background-color: #f5f5f5;
  width: 70%;
  height: 50%;
  padding: 15px 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
