import * as React from 'react';

import {
  Wrapper,
  ArrowWrapper,
  Chevron,
  ButtonWrapper,
} from './styled-components';

const titles = ['October', 'October', 'October'];

const subTitles = [
  'Fire Safety and Community',
  'Nocturnal Animals',
  'Outer Space',
];

const Header = ({ weekNum, setWeekNum }) => {
  const changeDay = (direction) => {
    if (direction === 'forward' && weekNum < 3) {
      setWeekNum(weekNum + 1);
    } else if (direction === 'backward' && weekNum > 1) {
      setWeekNum(weekNum - 1);
    }
  };

  return (
    <Wrapper>
      <h1>{titles[weekNum - 1]}</h1>
      <ArrowWrapper>
        {weekNum > 1 ? (
          <ButtonWrapper
            onClick={() => changeDay('backward')}
            isDisabled={weekNum <= 1}
          >
            <Chevron />
          </ButtonWrapper>
        ) : (
          <svg
            width="52"
            height="45"
            viewBox="0 0 52 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="52" height="45" fill="#EB212E" />
          </svg>
        )}
        <h2>{subTitles[weekNum - 1]}</h2>
        {weekNum < 3 ? (
          <ButtonWrapper onClick={() => changeDay('forward')}>
            <Chevron />
          </ButtonWrapper>
        ) : (
          <svg
            width="52"
            height="45"
            viewBox="0 0 52 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="52" height="45" fill="#EB212E" />
          </svg>
        )}
      </ArrowWrapper>
    </Wrapper>
  );
};

export default Header;
