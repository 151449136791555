import styled, { css } from 'styled-components';

import searchIcon from '../../assets/searchIcon.svg';

const smallestBreakpoint = '1500px';

const sharedCellStyles = css`
  border: 2px solid black;
  font-weight: bold;
  font-size: 14px;
`;

export const CustomTable = styled.table`
  border-collapse: collapse;
  width: 70%;
  margin-left: 25px;

  @media (max-width: ${smallestBreakpoint}) {
    width: 65%;
  }
`;

export const TR = styled.tr`
  ${sharedCellStyles}
  border: none;
  background-color: white;
  :first-child {
    background: none;
  }

  > td {
    :first-child {
      background-color: #eeedee;
    }
  }
`;

export const TH = styled.th`
  ${sharedCellStyles}
  height: 40px;
  background-color: #eeedee;
  ${({ hide }) =>
    hide &&
    css`
      border: none;
      background: none;
    `}
`;

export const TD = styled.td`
  ${sharedCellStyles}
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  width: 150px;
  height: 80px;
  position: relative;
  text-align: center;

  ${({ isFriday }) =>
    isFriday &&
    css`
      border-top: none;
      border-bottom: none;
    `}
`;

export const TDLabel = styled.span`
  width: 80%;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const DragWrapper = styled.div`
  position: sticky;
  height: 96vh;
  top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  background-color: #eeedee;
  align-content: flex-start;
  justify-content: space-evenly;
  overflow-y: scroll;
  border: 2px solid black;

  @media (max-width: ${smallestBreakpoint}) {
    width: 30%;
  }
`;

export const CellSearchBar = styled.input`
  flex-basis: 80%;
  padding: 10px 5px 10px 35px;
  font-size: 16px;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left center;
  background-position-x: 10px;
  border-radius: 5px;
  border: 2px solid black;
`;

export const SearchWrapper = styled.div`
  position: sticky;
  top: 0px;
  background-color: #eeedee;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 5;
`;

export const IconImage = styled.img`
  width: 75px;
  height: 75px;
`;
