import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Table from './components/DnDTable/Table';
import Header from './components/Header';
import Button from './components/Button';
import Logo from './components/Logo';
import WebOnlyOverlay from './components/WebOnlyOverlay';
import { useLocalStorage } from './hooks/utils';
import { useHandleReset } from './components/DnDTable/hooks';

import { downloadWeek } from './helpers';
import { ButtonWrapper, TableWrapper, AppContainer } from './styled-components';
import printableWeekOne from './assets/printables/1.pdf';
import printableWeekTwo from './assets/printables/2.pdf';
import printableWeekThree from './assets/printables/3.pdf';

const printables = {
  1: printableWeekOne,
  2: printableWeekTwo,
  3: printableWeekThree,
};

const App = () => {
  const [weekNum, setWeekNum] = useLocalStorage('weekNum', 1);

  const handleReset = useHandleReset(weekNum);

  const handleAccessPrintables = () => {
    const pdfViewer = window.open();
    pdfViewer.location.href = printables[weekNum];
  };

  return (
    <>
      <WebOnlyOverlay />
      <AppContainer>
        <Logo />
        <Header setWeekNum={setWeekNum} weekNum={weekNum} />

        <ButtonWrapper>
          <Button onClick={() => handleReset()}>Reset</Button>
          <Button onClick={() => downloadWeek(weekNum)}>Download</Button>

          <Button onClick={handleAccessPrintables}>Access Printable</Button>
        </ButtonWrapper>

        <DndProvider backend={HTML5Backend}>
          <TableWrapper>
            <Table weekNum={weekNum} />
          </TableWrapper>
        </DndProvider>
      </AppContainer>
    </>
  );
};

export default App;
