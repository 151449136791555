import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 10px 0 0 0;
  margin-right: 25px;
  align-self: flex-end;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin-top: -125px;
`;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  @media (max-width: 1100px) {
    display: none;
  }
`;
