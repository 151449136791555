import * as React from 'react';
import { useDrop } from 'react-dnd';

import CardModal from '../../CardModal';
import { TD } from '../styled-components';
import { HoverButtonOverlay } from '../DragCell/styled-components';

const { memo, useMemo } = React;

const DropCell = memo(
  ({
    accept,
    lastDroppedItem,
    onDrop,
    idx,
    isFriday,
    activityCards,
    index,
    canDrop: cellIsEmpty,
    borderColor,
  }) => {
    const [openCard, setOpenCard] = React.useState('');

    const [{ isOver, canDrop }, drop] = useDrop({
      accept,
      drop: cellIsEmpty ? onDrop : () => {},
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const isActive = isOver && canDrop;

    let backgroundColor;
    if (isActive && cellIsEmpty) {
      backgroundColor = 'white';
    } else if (canDrop) {
      backgroundColor = borderColor;
    }

    const cardData = useMemo(() => {
      if (!lastDroppedItem) return null;
      return activityCards.filter(
        (card) => card.title === lastDroppedItem.$set.name
      )?.[0];
    }, [lastDroppedItem, activityCards]);

    return (
      <TD ref={drop} backgroundColor={backgroundColor} isFriday={isFriday}>
        {lastDroppedItem && (
          <>
            {lastDroppedItem.$set.name}
            <HoverButtonOverlay
              onClick={() => setOpenCard(lastDroppedItem.$set.name)}
            >
              Open
            </HoverButtonOverlay>
            <CardModal
              isOpen={openCard === cardData.title}
              setOpenCard={setOpenCard}
              cardData={cardData}
            />
          </>
        )}
      </TD>
    );
  }
);

export default DropCell;
