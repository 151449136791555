import styled from 'styled-components';

export const StyledButton = styled.button`
  background-color: #ffd800;
  color: black;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 24px;
  padding: 0 20px;
  white-space: nowrap;
  margin: 5px;
  cursor: pointer;
`;
