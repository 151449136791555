import styled from 'styled-components';

export const Title = styled.h1`
  color: ${({ color }) => color || 'black'};
  text-align: center;
  margin: 10px 0px;
  font-size: 40px;
  max-width: 90%;
`;

export const SubTitle = styled.h2`
  font-weight: medium;
  text-align: center;
  margin: 0;
  font-size: 25px;
`;

export const Label = styled.span`
  font-weight: bold;
  margin-right: 5px;
  font-size: 15px;
`;

export const Data = styled.span`
  font-weight: normal;
  font-size: 15px;
`;

export const LabelDataWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  font-size: 25px;
  font-weight: normal;
  cursor: pointer;
  padding: 15px;
  top: 0;
  left: 5px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;
