import { useMemo } from 'react';

import { weekdaysDnd } from './constants';
import { IconImage } from './styled-components';
import data from '../../data.json';

import llCreate from '../../assets/icons/llCreative.png';
import llImagination from '../../assets/icons/llImagination.png';
import llSensoryNature from '../../assets/icons/llSensoryNature.png';
import llDesignBuild from '../../assets/icons/llDesignBuild.png';
import sgMath from '../../assets/icons/sgMath.png';
import sgScience from '../../assets/icons/sgScience.png';
import sgPuzzler from '../../assets/icons/sgPuzzler.png';
import sgReadingLiteracy from '../../assets/icons/sgReadingLiteracy.png';

export function useActivityLabels(weekNum) {
  const activityLabels = useMemo(() => {
    const uniquieCategories = new Set([]);
    data[weekNum]?.map((card) => uniquieCategories.add(card.categorization));

    return Array.from(uniquieCategories);
  }, [weekNum]);

  return activityLabels;
}

export function useBuiltLabels(activityLabels) {
  const builtLables = useMemo(
    () =>
      weekdaysDnd.map(() =>
        activityLabels.map(() => ({
          lastDroppedItem: null,
        }))
      ),
    [activityLabels]
  );
  return builtLables;
}

export function useHandleReset(weekNum) {
  const activityLabels = useActivityLabels(weekNum);
  const builtLabels = useBuiltLabels(activityLabels);

  return () => {
    const currentStoredDroppedCellNames =
      localStorage.getItem('droppedCellNames');
    const currentStoredCells = localStorage.getItem('cells');

    const newDroppedCellNames = JSON.parse(currentStoredDroppedCellNames);
    newDroppedCellNames[weekNum - 1] = [];

    const storedCells = JSON.parse(currentStoredCells);
    const activityDnd = [
      [...storedCells[0]],
      [...storedCells[1]],
      [...storedCells[2]],
    ];

    activityDnd[weekNum - 1] = [...builtLabels];

    localStorage.setItem('cells', JSON.stringify(activityDnd));

    localStorage.setItem(
      'droppedCellNames',
      JSON.stringify(newDroppedCellNames)
    );

    window.location.reload();
  };
}

export function useIconForSubCategory(category) {
  if (!category) return null;
  const formattedCategory = category.trim();
  const icons = {
    'LEARNING LABS: IMAGINATION': (
      <IconImage src={llImagination} alt="category icon" />
    ),
    'LEARNING LABS: CREATIVE': <IconImage src={llCreate} alt="category icon" />,
    'LEARNING LABS: SENSORY AND NATURE': (
      <IconImage src={llSensoryNature} alt="category icon" />
    ),
    'LEARNING LABS: DESIGN AND BUILD': (
      <IconImage src={llDesignBuild} alt="category icon" />
    ),
    'SMALL GROUP: LITERACY AND READING': (
      <IconImage src={sgReadingLiteracy} alt="category icon" />
    ),
    'SMALL GROUP: MATH': <IconImage src={sgMath} alt="category icon" />,
    'SMALL GROUP: SCIENCE': <IconImage src={sgScience} alt="category icon" />,
    'SMALL GROUP: PUZZLERS': <IconImage src={sgPuzzler} alt="category icon" />,
  };

  return icons[formattedCategory];
}
