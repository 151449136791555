import { memo } from 'react';
import { useDrag } from 'react-dnd';

import {
  Wrapper,
  NameLabel,
  CategoryLabel,
  DataWrapper,
  HoverButtonOverlay,
} from './styled-components';

const DragCell = memo(
  ({
    name,
    type,
    isDropped,
    undoDrop,
    borderColor,
    category,
    setBorderColor,
  }) => {
    const [{ opacity }, drag] = useDrag(
      () => ({
        type,
        item: { name },
        collect: (monitor) => {
          if (monitor.isDragging()) {
            setBorderColor(borderColor);
          }
          return {
            opacity: monitor.isDragging() ? 0.4 : 1,
          };
        },
      }),
      [name, type]
    );

    return isDropped ? (
      <Wrapper borderColor={borderColor}>
        <DataWrapper isDropped={isDropped}>
          <NameLabel>{name}</NameLabel>
          <CategoryLabel> {category}</CategoryLabel>
        </DataWrapper>
        <HoverButtonOverlay onClick={() => undoDrop(name)}>
          Remove
        </HoverButtonOverlay>
      </Wrapper>
    ) : (
      <Wrapper ref={drag} style={{ opacity }} borderColor={borderColor}>
        <DataWrapper isDropped={isDropped}>
          <NameLabel>{name}</NameLabel>
          <CategoryLabel> {category}</CategoryLabel>
        </DataWrapper>
      </Wrapper>
    );
  }
);

export default DragCell;
