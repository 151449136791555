export const weekdays = [
  '',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];
export const weekdaysDnd = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];

export const activityLabels = [
  'Welcome Circle',
  'Reading & Literacy Learning Lab',
  'Math Learning Lab ',
  'Science Learning Lab ',
  'Puzzlers Learning Lab',
  'Imagination Learning Lab',
  'Creative Learning Lab',
  'Design & Build Learning Lab',
  'Sensory & Nature Learning Lab',
  'Reflection Circle',
  'At Home Connection',
];
